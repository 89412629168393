import { Settings } from "luxon";
import { ApiDate, ApiDay, ApiHour } from "date/apiDate";
import { Optional } from "model/types";
import { isEmpty, isNil, isUndefined } from "lodash-es";
import { joinText } from "utils/string";
import {
  DateTimeOperation,
  DurationInput,
  DurationUnitValues
} from "date/duration";
import { getIntervalWindowList, Interval } from "./interval";
import { DateFormat } from "./dateFormat";
import { DateTime } from "./datetime";
import { isApiDate } from "./apiDate";

export type DateInput = DateTime | ApiDate;

// TODO This is a luxon bug. Remove when fixed
const getCurrentLocale = () =>
  isEmpty(Settings.defaultLocale) ? "es-AR" : Settings.defaultLocale;

export const currentLocale = getCurrentLocale();

export const getDateFormatFromLocale = (): DateFormat => {
  const currLocale = currentLocale;
  if (["es", "es-AR"].includes(currLocale)) return DateFormat.Spanish;

  // Default to Spanish
  return DateFormat.Spanish;
};

export const dateInputToDateTime = (date: DateInput): DateTime =>
  isApiDate(date) ? DateTime.fromApiDate(date) : date;

export const optionalDateInputToDateTime = (
  date?: DateInput
): Optional<DateTime> => {
  if (!date) return undefined;
  return isApiDate(date) ? DateTime.fromApiDate(date) : date;
};

export const applyFormatToDate = (
  date: Optional<DateInput>,
  format: string,
  defaultValue?: string,
  tz?: string
): Optional<string> => {
  if (date === undefined) return defaultValue;
  let dateTime = dateInputToDateTime(date);
  if (!dateTime.isValid) return defaultValue;
  if (tz != null) {
    dateTime = dateTime.setZone(tz);
    if (!dateTime.isValid) return defaultValue;
  }
  return dateTime.toFormat(format);
};

export const getDateTimeFromDateFormat = (
  date: ApiDate,
  format: DateFormat,
  zone?: string
): DateTime => {
  return DateTime.fromFormat(date, format, {
    zone
  });
};

export const applyCurrLocaleFormatToDate = (
  date: Optional<DateInput>,
  defaultStr = "-"
): Optional<string> =>
  applyFormatToDate(date, getDateFormatFromLocale(), defaultStr);

export const toApiDate = (date: DateInput): ApiDate =>
  dateInputToDateTime(date).toISO();

export const toApiDay = (date: DateInput): ApiDay =>
  applyFormatToDate(date, DateFormat.ApiDay) ?? "";

export const toApiHour = (date: DateInput): ApiHour =>
  applyFormatToDate(date, DateFormat.SecTime) ?? "";

export const applyISODateFormatToDate = (date: DateInput): ApiDate =>
  dateInputToDateTime(date).toISODate();

export const getCurrentDateTime = (): DateTime => DateTime.local();

export const getCurrentDateTimestamp = (): number => DateTime.now().toMillis();

export const getDaysAgo = (startDate?: DateInput): number => {
  if (startDate) {
    const dateTimeStartDate = dateInputToDateTime(startDate);
    const today = DateTime.local().startOf("day");
    const startOfDate = dateTimeStartDate.startOf("day");
    return today.diff(startOfDate, "days").toObject().days ?? 0;
  }
  return 0;
};

export const getDaysDiffToCurrentDate = (date?: ApiDate): number =>
  Math.abs(getDaysAgo(date));

export const getYearsDiff = (
  startDate: DateInput,
  endDate: DateInput
): number => {
  const start = dateInputToDateTime(startDate);
  const end = dateInputToDateTime(endDate);
  return Math.trunc(end.diff(start, "years").toObject().years ?? 0);
};

export const getHoursDiff = (
  startDate: DateInput,
  endDate: DateInput
): number => {
  const start = dateInputToDateTime(startDate);
  const end = dateInputToDateTime(endDate);
  return Math.trunc(end.diff(start, "hours").toObject().hours ?? 0);
};

export const getDaysDiff = (
  startDate: DateInput,
  endDate: DateInput,
  inclusiveEndDate?: boolean
): number => {
  const start = dateInputToDateTime(startDate).startOf(DurationUnitValues.Day);
  const end = dateInputToDateTime(endDate).startOf(DurationUnitValues.Day);
  const adjustedEndDate = inclusiveEndDate ? end.plus({ days: 1 }) : end;
  const interval = Interval.fromDateTimes(start, adjustedEndDate);

  return interval.length(DurationUnitValues.Days);
};

export const getMillisecondsDiff = (
  startDate: DateInput,
  endDate: DateInput
): number => {
  const startMs = dateInputToDateTime(startDate).toMillis();
  const endMs = dateInputToDateTime(endDate).toMillis();
  return endMs - startMs;
};

export const getYearsSince = (startDate: DateInput): number => {
  return getYearsDiff(startDate, DateTime.local());
};

export const isSameDay = (d1?: DateTime, d2?: DateTime): boolean => {
  return isUndefined(d1) || isUndefined(d2)
    ? false
    : d1.ordinal === d2.ordinal && d1.year === d2.year;
};

export const dateTimeToQueryParam = (d?: DateTime): Optional<string> => {
  return d?.toSeconds().toString();
};

export const dateTimeFromQueryParam = (x?: string): Optional<DateTime> => {
  if (x === undefined || x === "" || Number.isNaN(Number(x)) || Number(x) < 0)
    return undefined;
  return DateTime.fromSeconds(Number(x));
};

export const dateTimeToApiDateOrUndefined = (
  d?: DateTime
): Optional<string> => {
  return d && d.isValid ? toApiDate(d) : undefined;
};

export const dateTimeFromDatetimeWithTimeZone = (
  date: DateTime,
  timeZone: string
): Optional<DateTime> => {
  return date.setZone(timeZone);
};

export const dateTimeFromApiDateWithTimeZoneOrUndefined = (
  date?: ApiDate,
  timeZone?: string
): Optional<DateTime> => {
  if (isEmpty(date) || isUndefined(date)) return undefined;
  return timeZone !== undefined
    ? DateTime.fromApiDateWithTimeZone(date, timeZone)
    : optionalDateInputToDateTime(date);
};

export const dateFromApiDateOrUndefined = (x?: string): Optional<DateTime> => {
  if (x === undefined || isEmpty(x)) return undefined;
  return DateTime.fromApiDate(x, true);
};

export const dateTimeFromApiDateOrUndefined = (
  x?: string
): Optional<DateTime> => {
  if (x === undefined || x === "") return undefined;
  return DateTime.fromApiDate(x);
};

export interface TimeOfDay {
  hour: number;
  minute: number;
  second: number;
}

export const getTimeOfDayFromTimeString = (time: ApiHour): TimeOfDay => {
  const [hour, minute, second] = time.split(":").map(v => Number(v));
  return { hour: hour ?? 0, minute: minute ?? 0, second: second ?? 0 };
};

/**
 * @param unixTimestamp Unix epoch time in seconds
 */
export const unixTimestampToApiDate = (unixTimestamp: number): ApiDate =>
  toApiDate(DateTime.fromSeconds(unixTimestamp));

/**
 * @param millis Unix epoch time in milliseconds
 */
export const unixMillisToApiDateOrUndefined = (
  millis?: number
): Optional<ApiDate> =>
  millis ? toApiDate(DateTime.fromMillis(millis)) : undefined;

export const dateTimeToUnixTimestamp = (dateTime: DateTime): number =>
  dateTime.toSeconds();

export const apiDateToDateTime = (apiDate: ApiDate): DateTime =>
  DateTime.fromApiDate(apiDate);

export const apiDateToUnixTimestamp = (apiDate: ApiDate): number =>
  dateTimeToUnixTimestamp(apiDateToDateTime(apiDate));

export const timeOfDayIsBefore = (
  timeOfDay: ApiHour,
  comparand: ApiHour
): boolean => timeOfDay < comparand;

export const datesAreEqual = (d1: DateTime, d2: DateTime): boolean => {
  return d1.toMillis() === d2.toMillis();
};

export const dateIsBefore = (date: DateTime, comparand: DateTime): boolean => {
  return date.toMillis() < comparand.toMillis();
};

export const dateTimeIsBeforeOrEqual = (
  date: DateTime,
  comparand: DateTime
): boolean => {
  return dateIsBefore(date, comparand) || datesAreEqual(date, comparand);
};

export const dateIsAfter = (date: DateTime, comparand: DateTime): boolean => {
  return date.toMillis() > comparand.toMillis();
};

export const dateTimeIsAfterOrEqual = (
  date: DateTime,
  comparand: DateTime
): boolean => {
  return dateIsAfter(date, comparand) || datesAreEqual(date, comparand);
};

export const daysAreEqual = (d1: DateTime, d2: DateTime): boolean => {
  return datesAreEqual(d1.startOf("day"), d2.startOf("day"));
};

export const dayIsBefore = (date: DateTime, comparand: DateTime): boolean => {
  const d1 = date.startOf("day");
  const d2 = comparand.startOf("day");
  return dateIsBefore(d1, d2);
};

export const dayIsAfter = (date: DateTime, comparand: DateTime): boolean => {
  const d1 = date.startOf("day");
  const d2 = comparand.startOf("day");
  return dateIsAfter(d1, d2);
};

export const dayIsAfterOrEqual = (
  date: DateTime,
  comparand: DateTime
): boolean => {
  const isAfter = dayIsAfter(date, comparand);
  const isEqual = daysAreEqual(date, comparand);
  return isAfter || isEqual;
};

export const dayIsBeforeOrEqual = (
  date: DateTime,
  comparand: DateTime
): boolean => {
  const isBefore = dayIsBefore(date, comparand);
  const isEqual = daysAreEqual(date, comparand);
  return isBefore || isEqual;
};

export const hoursAreEqual = (d1: DateTime, d2: DateTime): boolean => {
  return datesAreEqual(d1.startOf("hour"), d2.startOf("hour"));
};

export const minutesAreEqual = (d1: DateTime, d2: DateTime): boolean => {
  return datesAreEqual(d1.startOf("minute"), d2.startOf("minute"));
};

export const monthsAndYearsAreEqual = (d1: DateTime, d2: DateTime): boolean => {
  return (
    datesAreEqual(d1.startOf("month"), d2.startOf("month")) &&
    d1.year === d2.year
  );
};

export const isAfterToday = (date: DateTime): boolean => {
  return DateTime.local().endOf("day") < date;
};

export const isAfterNow = (date: DateInput): boolean => {
  const d = dateInputToDateTime(date);
  return DateTime.local() < d;
};

export const getCurrentDateAsString = (): string => toApiDate(DateTime.now());

export const getArrayDateTimesFromDateTimeInterval = (
  startDate: DateInput,
  endDate: DateInput
): DateTime[] => {
  const start = dateInputToDateTime(startDate).startOf("day");
  const end = dateInputToDateTime(endDate).startOf("day");
  const dateArray: DateTime[] = [];
  let currentDate = start;
  while (currentDate <= end) {
    dateArray.push(currentDate);
    currentDate = currentDate.plus({ days: 1 });
  }
  return dateArray;
};

export const getDateTimeMillisBefore = (
  millis: number,
  referenceDate: DateInput
): DateTime => {
  return dateInputToDateTime(referenceDate).minus({
    milliseconds: millis
  });
};

export const getIsoApiDateMillisBefore = (
  millis: number,
  referenceDate: DateInput
): ApiDate => {
  return getDateTimeMillisBefore(millis, referenceDate).toISO();
};

export const dateTimeIsBetweenApiHours = (
  datetime: DateTime,
  start?: ApiHour,
  end?: ApiHour
): boolean => {
  if (!start || !end) return false;
  const windowIntervalList = getIntervalWindowList(datetime, start, end);
  return windowIntervalList.some(interval => interval.contains(datetime));
};

export const setDateToMidnight = (date: DateTime): DateTime =>
  date.set({
    hour: 0,
    minute: 0,
    second: 0,
    millisecond: 0
  });

export const setApiDateToMidnight = (date?: ApiDate): Optional<ApiDate> => {
  const dateTime = dateTimeFromApiDateOrUndefined(date);
  return dateTimeToApiDateOrUndefined(
    dateTime ? setDateToMidnight(dateTime) : undefined
  );
};

export const setDateMiddleDay = (date: DateTime): DateTime =>
  date.set({
    hour: 12,
    minute: 0,
    second: 0,
    millisecond: 0
  });

export const setDateMinutesAndMillisecondsToZero = (date: DateTime): DateTime =>
  date.set({
    second: 0,
    millisecond: 0
  });

export const getListOfDateTimesBetweenDates = (
  durationInput: DurationInput,
  startDate?: DateTime,
  endDate?: DateTime
): DateTime[] => {
  if (startDate === undefined || endDate === undefined) return [];
  const interval = Interval.fromDateTimes(startDate, endDate);

  return interval.splitBy(durationInput).map(d => d.start);
};

export const getListOfApiDateTimesBetweenDates = (
  durationInput: DurationInput,
  startDate?: DateTime,
  endDate?: DateTime
): ApiDate[] => {
  return getListOfDateTimesBetweenDates(durationInput, startDate, endDate).map(
    d => toApiDate(d)
  );
};

export enum EndDateMode {
  NextDay = "NextDay",
  EndDay = "EndDay"
}

export const readDateTimeAsEndDate = (
  date: DateTime,
  mode: EndDateMode = EndDateMode.NextDay
): DateTime => {
  if (mode === EndDateMode.NextDay) {
    const newDate = date.minus({ days: 1 });
    return setDateToMidnight(newDate);
  }
  return date;
};

interface ReadApiDateAsEndDateProps {
  date?: ApiDate;
  mode?: EndDateMode;
  timezone?: string;
}
export const readApiDateAsEndDate = (
  props: ReadApiDateAsEndDateProps
): Optional<DateTime> => {
  const mode = props.mode ?? EndDateMode.NextDay;
  const dateTime = dateTimeFromApiDateWithTimeZoneOrUndefined(
    props.date,
    props.timezone
  );
  return dateTime ? readDateTimeAsEndDate(dateTime, mode) : undefined;
};

export const setDateTimeAsEndDate = (
  date: DateTime,
  mode: EndDateMode = EndDateMode.NextDay
): DateTime => {
  if (mode === EndDateMode.NextDay) {
    const newDate = date.plus({ days: 1 });
    return setDateToMidnight(newDate);
  }
  return date.endOf(DurationUnitValues.Day);
};

export const setApiDateAsEndDate = (
  date: ApiDate,
  mode: EndDateMode = EndDateMode.NextDay
): ApiDate => {
  const datetime = DateTime.fromApiDate(date);
  return toApiDate(setDateTimeAsEndDate(datetime, mode));
};

export const setDateTimeAsStartDate = (date: DateTime): DateTime => {
  return setDateToMidnight(date);
};

export const setApiDateAsStartDate = (date: ApiDate): ApiDate => {
  const datetime = DateTime.fromApiDate(date);
  return toApiDate(setDateTimeAsStartDate(datetime));
};

const getEndDateText = (
  dateFormat: DateFormat,
  datetime?: DateTime,
  mode: EndDateMode = EndDateMode.NextDay
): Optional<ApiDate> => {
  const fixedDate = datetime
    ? readDateTimeAsEndDate(datetime, mode)
    : undefined;
  return fixedDate?.isValid ? fixedDate.toFormat(dateFormat) : undefined;
};

export const getPeriodTextWithIanaTimeZoneOrTimezone = (
  periodStartDate?: ApiDate,
  periodEndDate?: ApiDate,
  timezone?: string
): Optional<string> => {
  const startDateWithIanaTimeZone = dateTimeFromApiDateWithTimeZoneOrUndefined(
    periodStartDate,
    timezone
  );
  const endDateWithIanaTimeZone = dateTimeFromApiDateWithTimeZoneOrUndefined(
    periodEndDate,
    timezone
  );
  const startDate = startDateWithIanaTimeZone?.isValid
    ? startDateWithIanaTimeZone.toFormat(DateFormat.Spanish)
    : undefined;
  const endDate = getEndDateText(DateFormat.Spanish, endDateWithIanaTimeZone);
  const period = isNil(startDate) || isNil(endDate) ? [] : [startDate, endDate];
  return joinText(period, " - ");
};

interface AdjustedDateTimeData {
  durationUnit: DurationUnitValues;
  operation: DateTimeOperation;
  durationAmount: number;
  date?: DateTime;
}

export const getAdjustedDateTime = ({
  durationUnit,
  operation,
  durationAmount = 1,
  date = setDateToMidnight(getCurrentDateTime())
}: AdjustedDateTimeData): DateTime => {
  if (operation === DateTimeOperation.Plus)
    return date.plus({ [durationUnit]: durationAmount });
  return date.minus({ [durationUnit]: durationAmount });
};

export const isDateMidnight = (date: DateTime): boolean =>
  date.hour === 0 && date.minute === 0;

export const areMidnightHoursAndEquals = (
  date1: DateTime,
  date2: DateTime
): boolean => isDateMidnight(date1) && isDateMidnight(date2);
